import React from 'react';
import Button from '@mui/material/Button';
import ButtonB from 'react-bootstrap/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useContextData } from '../../../core/context/index'
import { deleteCookie } from '../../../core/helper/cookie';
import SearchBar from '../../../Component/SearchBar/SearchBar';

const HeaderHome = ({ userLogin }) => {
    const { myProfile, setUserProfile } = useContextData();
    const navigate = useNavigate();
    const pathname = useLocation().pathname;
    const theme = createTheme({
        palette: {
            mode: 'light',
        },
    });
    const menuBar = <ThemeProvider theme={theme}>
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <Button variant="contained" className='btn-user-home' sx={{ textTransform: 'none' }} {...bindTrigger(popupState)}>
                        <span className='btn-font'>{myProfile && <>{myProfile?.firstname ? myProfile?.firstname : myProfile.email ? myProfile.email : myProfile.phoneno}{" "}</>}</span> <KeyboardArrowDownIcon sx={{ color: "white" }} />
                    </Button>
                    <Menu {...bindMenu(popupState)} >
                        <MenuItem sx={{ fontSize: '14px', fontWeight: 600 }} onClick={popupState.close} as={Link} to="/my/profile" className='text-decoration-none text-dark'><img src="/assets/UserHome.svg" alt="" className='img-fluid me-1' width={22} /> Profile</MenuItem>
                        <MenuItem sx={{ fontSize: '14px', fontWeight: 600 }} onClick={popupState.close} as={Link} to="/my/identification" className='text-decoration-none text-dark'><img src="/assets/LockLaminatedHome.svg" alt="" className='img-fluid me-1' width={22} />Identification</MenuItem>
                        <MenuItem sx={{ fontSize: '14px', fontWeight: 600 }} onClick={popupState.close} className='text-decoration-none text-dark' as={Link} to="/my/payment"><img src="/assets/CreditCardHome.svg" alt="" className='img-fluid me-1' width={22} />Payment</MenuItem>
                        <MenuItem sx={{ fontSize: '14px', fontWeight: 600 }} onClick={popupState.close} className='text-decoration-none text-dark' as={Link} to="/my/referral"><img src="/assets/UserGearHome.svg" alt="" className='img-fluid me-1' width={22} />Referral</MenuItem>
                        <MenuItem sx={{ fontSize: '14px', fontWeight: 600 }} onClick={popupState.close} className='text-decoration-none text-dark' as={Link} to="/my/SpotTradeHistory"><img src="/assets/ClockCounterClockwiseHome.svg" alt="" className='img-fluid me-1' width={22} />Trade history</MenuItem>
                        <MenuItem sx={{ fontSize: '14px', fontWeight: 600 }} onClick={popupState.close} className='text-decoration-none text-dark' as={Link} to="/my/wallet/history"><img src="/assets/ClockCounterClockwiseHome.svg" alt="" className='img-fluid me-1' width={22} />Transactions</MenuItem>
                        <MenuItem sx={{ fontSize: '14px', fontWeight: 600 }} onClick={() => {
                            popupState.close();
                            deleteCookie('userToken');
                            setUserProfile();
                            navigate('/login');
                        }} className='text-decoration-none text-dark'><img src="/assets/SignOutHome.svg" alt="" className='img-fluid me-1' width={22} />Logout</MenuItem>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    </ThemeProvider >

    return (
        <div style={{ color: "black" }} className='py-0 bg-navbar'>
            {['lg'].map((expand) => (
                <Navbar key={expand} expand={expand} collapseOnSelect className='py-2'>
                    <Container fluid>
                        <Navbar.Brand href="#" as={Link} to={"/"} className='me-0 pe-0'>
                            < img src='./logo.png' alt="logo" className='img-fluid' style={{ position: 'relative', top: '1px' }} width={100} />
                        </Navbar.Brand>
                        <Navbar.Toggle style={{ fontSize: '14px' }} aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            className='w-75'
                            style={{ background: "white", color: "black" }}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    < img src='./logo.png' alt="logo" className='img-fluid' width={100} />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className='pt-0'>
                                <Nav className="me-auto align-items-start align-items-lg-center flex-grow-1 homeNavCss">
                                    <Nav.Link as={Link} to={"/markets"} href="#market">Market</Nav.Link>
                                    <Nav.Link as={Link} to={"/signal"} href="#signal">Signal</Nav.Link>
                                    <NavDropdown
                                        title="Trade"
                                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        style={{ backgroundColor: "transparent", marginLeft: "0px" }}
                                        className={`text-dark  bootstrap-custom-dropdown-header-home ${(pathname.includes("/home")) ? "" : ""}`}
                                    >
                                        <NavDropdown.Item href="#action4" as={Link} to={"/spot/TRX_INR"} className='fw-bold'>Spot</NavDropdown.Item>
                                        {/* <NavDropdown.Item href="#action3" as={Link} to={"/Insta-Trade"} className='fw-bold'>Instant Trade</NavDropdown.Item> */}
                                        <NavDropdown.Item href="#action2" className='fw-bold'
                                            as={Link} to={"/futures/trade/XBTUSDTM"}
                                        >Futures Trading</NavDropdown.Item>

                                    </NavDropdown>
                                    <Nav.Link href="https://utistaking.com/" target='_blank'>Staking</Nav.Link>
                                    {userLogin ?
                                        <Nav.Link href="#portfolio" as={Link} to={"/my/dashboard"}>Portfolio</Nav.Link> : null
                                    }
                                    {/* <Nav.Link>
                                        <Googletranslate />
                                    </Nav.Link> */}
                                    <Nav.Link>
                                        <SearchBar />
                                    </Nav.Link>
                                </Nav>
                                <Nav className=" align-items-start align-items-lg-center gapIssue">
                                    {
                                        userLogin ? <Nav.Link >{menuBar} </Nav.Link> : <>
                                            <Nav.Link href="#login" as={Link} to={"/login"} >

                                                <ButtonB variant="light" className='border-0 text-white fw-bold' style={{
                                                    background:
                                                        'radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #38C558',
                                                    height: '32px', fontSize: '12px', padding: '0px 20px', fontWeight: 600
                                                }}>Login</ButtonB>

                                            </Nav.Link>
                                            <Nav.Link href="#register" as={Link} to={"/register"}>
                                                <ButtonB variant="light" style={{ height: '32px', fontSize: '12px', padding: '0px 20px', fontWeight: 600 }} className='border text-dark'>Register</ButtonB>

                                            </Nav.Link></>
                                    }
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
            <hr style={{ opacity: '0.25', margin: 0 }} />
        </div>
    );
};

export default HeaderHome;