import React from 'react';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import { FaDiscord } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useContextData } from '../../core/context/index';

const FooterHome = () => {
    const navigate = useNavigate();
    const { siteSettings } = useContextData();
    const year = new Date().getFullYear();
    return (
        <section className='pt-3'>
            <div className=''>
                <div className='mb-2 text-dark'>
                    <div className='container'>
                        <div className='row pb-1 mx-auto gx-2 gy-2'>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <img className='img-fluid' id='navbar-img' src='./logo.png' alt="logo" />
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'></div>
                            <div className='col-6 col-sm-6 col-md-2'></div>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <h5 className='mb-0 pt-2 fw-bold'>Your <span className='text-warning fw-bold'>Crypto</span> Gateway</h5>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'></div>
                            <div className='col-6 col-sm-6 col-md-2'></div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row mx-auto gx-2 gy-3 fontSizeFooter extraHover'>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900 pb-2 text-logo-grad-home'>Corporate</p>
                            <ul className='p-0'>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/about')}>About Us</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/my/referral')}>Referrals</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/terms')}>Terms Of Use</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/privacypolicy')}>Privacy Policy</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/faq')}>FAQ</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/blogs')}>Blogs</li>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900 pb-2 text-logo-grad-home'>Products</p>
                            <ul className='p-0'>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/spot/TRX_INR')}>Spot Trading</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate("/futures/trade/XBTUSDTM")}>Futures Trade</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/spot/UNITIC_INR')}>UNITIC TOKEN</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/crypto_trading_platform')}>Crypto Trading</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => window.open("https://utistaking.com/", "_blank")}>Staking</li>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900 pb-2 text-logo-grad-home'>Services</p>
                            <ul className='p-0'>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/help-center')}>Help Center</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => window.open("https://www.uniticexchange.com/sitemap.xml", "_blank")}>Sitemap</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/help-center')}>Technical Support</li>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900 pb-2 text-logo-grad-home'>Business</p>
                            <ul className='p-0'>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/otc_desk')}>OTC Desk</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/help-center')}>Token Listing</li>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/fees')}>Fees</li>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900 pb-2 text-logo-grad-home'>Developer</p>
                            <ul className='p-0'>
                                <li style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/api-docs')}>API Documentation</li>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900 pb-2 text-logo-grad-home'>Social media</p>
                            <div className='row' style={{ gap: '10px' }}>
                                <div className='col-2' style={{ cursor: 'pointer' }} onClick={() => window.open("https://x.com/unitic_exchange", "_blank")}>
                                    <TwitterIcon style={{ color: 'black' }} />
                                </div>
                                <div className='col-2' style={{ cursor: 'pointer' }} onClick={() => window.open("https://t.me/uniticexchange", "_blank")}>
                                    <TelegramIcon style={{ color: 'black' }} />
                                </div>
                                <div className='col-2' style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.instagram.com/uniticexchange/", "_blank")}>
                                    <InstagramIcon style={{ color: 'black' }} />
                                </div>
                                <div className='col-2' style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.youtube.com/@uniticexchange", "_blank")}>
                                    <YouTubeIcon style={{ color: 'black' }} />
                                </div>
                                <div className='col-2' style={{ cursor: 'pointer' }} onClick={() => window.open("https://discord.com/channels/1022891308710379561/1022891309456949300", "_blank")}>
                                    <FaDiscord style={{ fontSize: '24px', color: 'black' }} />
                                </div>
                                <div className='col-2' style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.linkedin.com/company/unitic-exchange/", "_blank")}>
                                    <LinkedInIcon style={{ color: 'black' }} />
                                </div>
                                <div className='col-2' style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.facebook.com/uniticexchange", "_blank")}>
                                    <FacebookRoundedIcon style={{ color: 'black' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container reduceSpaceFooter'>
                    <hr style={{ opacity: '0.5' }} />
                    <div className='row pb-3 align-items-start'>
                        <div className='col'>
                            {siteSettings && (
                                <p className='very-small-text-2 mb-0'>{siteSettings.siteName}</p>
                            )}
                        </div>
                        <div className='col'>
                            <p className='very-small-text-2 mb-0 text-end'>All rights reserved {year}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FooterHome;
